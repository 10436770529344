.content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #780068;
}

.input-field{
  margin: 10px;
  border-radius: 10px;
  border: none;
  width: 500px;
  height: 30px;
}

.label{
  color: white;
  font-size: 12px;
}
 .submit-btn{
  margin-left: 10px;
  margin-top: 15px;
  border-radius: 3px;
  border: none;
  background-color: #f5f5f5;
  color: #780068;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .input{
  width: 100%;
  height: 35px;
  padding-left: 10px;
  font-size: small;
 }

 .submit-container{
  display: flex;
  justify-content: center;
  align-items: center;
 }

 h1{
  color: white;
  /* font-size: 20px; */
  padding-bottom: 40px;
  padding-top: 40px;
 }

 .error-msg{
  color: white;
  padding-left: 10px;
 }